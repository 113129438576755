<template>
  <div class="row g-7">
    <!--begin::Contact groups-->
    <div class="col-lg-6 col-xl-4">
      <div class="card card-flush mb-7">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>İş İlanı</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <div class="d-flex align-items-center">
            <Field
              name="jobPosting"
              data-control="select1"
              data-hide-search="true"
              data-placeholder="Mülakat seçiniz..."
              class="form-select form-select-lg form-select-solid"
              as="select"
              value="1"
              required
            >
              <option value="1" selected>
                Hybrid Çalışacak Frontend Developer
              </option>
              <option value="2">Ofisten Çalışacak Fullstack Developer</option>
              <option value="3">Tecrübeli Backend Developer</option>
            </Field>
          </div>
        </div>
        <!--end::Card body-->
      </div>
      <div class="card card-flush mb-7">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>Hybrid Çalışacak Frontend Developer</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Contact group-->
          <div class="mb-5">
            <a class="fs-6 fw-bold text-gray-800 text-hover-primary"
              >Pozisyon:
            </a>
            <div class="badge badge-light-info d-inline">
              Frontend Developer
            </div>
          </div>

          <!-- <div class="d-flex flex-stack">
            <a class="fs-6 fw-bold text-gray-800 text-hover-primary"
              >Pozisyon:
            </a>
            <div class="badge badge-light-info d-inline">
              Frontend Developer
            </div>
          </div> -->

          <a class="fs-6 fw-bold text-gray-800 text-hover-primary mt-3"
            >Tanım:
          </a>
          <p>
            Yaratıcı ve dinamik bir yazılım ekibine katılacak, kullanıcı dostu
            web uygulamaları geliştirmek için güçlü bir frontend developer
            arıyoruz. Çalışma modeli hybrid olacak, ofis ve uzaktan çalışma
            fırsatı sunulmaktadır.
          </p>
          <a class="fs-6 fw-bold text-gray-800 mt-3">Teklif Edilen İmkanlar</a>
          <ul>
            <li>Hybrid çalışma modeli (ofis ve uzaktan çalışma seçeneği)</li>
            <li>Esnek çalışma saatleri</li>
            <li>Yaratıcı ve destekleyici bir çalışma ortamı</li>
            <li>
              Yeteneklerinizi geliştirmek için eğitim ve gelişim fırsatları
            </li>
          </ul>

          <!--begin::Contact group-->
          <!--begin::Permissions-->
          <div class="d-flex flex-column text-gray-600">
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Minimum 3 yıl iş
              tecrübesi
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> İlgili mühendislik
              bölümlerinden mezun
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Vuejs, Typescript ile
              tecrübesi olan
            </div>

            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span>
              <em>9 adet daha..</em>
            </div>
          </div>
          <!--end::Permissions-->
        </div>
        <!--end::Card body-->
      </div>

      <!--begin::Contact group wrapper-->
      <div class="card card-flush">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>Mülakat Sayısı</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Contact groups-->
          <div class="d-flex flex-column gap-5">
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="
                  fs-6
                  fw-bold
                  text-gray-800 text-hover-primary text-active-primary
                  active
                "
                >Toplam Mülakat</a
              >
              <div class="badge badge-light-primary">11</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="fs-6 fw-bold text-gray-800 text-hover-primary"
                >Tamamlanan</a
              >
              <div class="badge badge-light-primary">5</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="fs-6 fw-bold text-gray-800 text-hover-primary"
                >Devam Eden</a
              >
              <div class="badge badge-light-primary">4</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                href="/metronic8/demo1/apps/contacts/getting-started.html"
                class="fs-6 fw-bold text-danger text-hover-primary"
                >İptal Olan</a
              >
              <div class="badge badge-light-danger">2</div>
            </div>
            <!--begin::Contact group-->
          </div>
          <!--end::Contact groups-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contact group wrapper-->
    </div>
    <!--end::Contact groups-->

    <!--begin::Content-->
    <div class="col-xl-8">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <!--begin::Contacts-->
        <div class="card card-flush h-lg-100" id="kt_contacts_main">
          <!--begin::Card header-->
          <div class="card-header pt-3" id="kt_chat_contacts_header">
            <!--begin::Card title-->
            <div class="card-title">
              <h2>Aday Davet Et</h2>
            </div>
            <!--end::Card title-->
          </div>
          <!--end::Card header-->

          <!--begin::Card body-->
          <div class="card-body pt-5">
            <div class="fv-row mb-8">
              <label class="form-label fs-5">Birden Fazla Aday Davet Et</label>
              <!--begin::Dropzone-->
              <div
                class="dropzone dz-clickable"
                id="kt_modal_create_project_settings_logo"
              >
                <!--begin::Message-->
                <div class="dz-message needsclick">
                  <!--begin::Icon-->
                  <i class="ki-duotone ki-file-up fs-3hx text-primary"
                    ><span class="path1"></span><span class="path2"></span
                  ></i>
                  <!--end::Icon-->

                  <!--begin::Info-->
                  <div class="ms-4">
                    <h3 class="dfs-3 fw-bold text-gray-900 mb-1">
                      Aday listesi yükle!
                    </h3>
                    <span class="fw-semibold fs-4 text-muted"
                      >Yalnızca .xlsx ve .csv uzantılı dosyalar</span
                    >
                  </div>
                  <!--end::Info-->
                </div>
              </div>
              <!--end::Dropzone-->
            </div>

            <!--begin::YA DA Section-->
            <div class="text-center my-4">
              <span class="fw-bold text-gray-900">YA DA</span>
            </div>

            <!--begin::Aday Ekle-->
            <div class="fv-row mb-10">
              <div class="mb-10">
                <label class="form-label required">Aday Adı Soyadı</label>
                <Field
                  name="candidateName"
                  class="form-control form-control-lg form-control-solid"
                />
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-10">
                    <label class="form-label required">E-posta</label>
                    <Field
                      name="candidateEmail"
                      class="form-control form-control-lg form-control-solid"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-10">
                    <label class="form-label required">Telefon</label>
                    <Field
                      name="candidatePhone"
                      class="form-control form-control-lg form-control-solid"
                    />
                  </div>
                </div>
              </div>

              <div class="mb-2">
                <label class="form-label required">Cv Yükle</label>
                <input
                  type="file"
                  name="candidateCv"
                  class="form-control form-control-lg form-control-solid"
                />
              </div>
              <div class="text-gray-600 fw-bold fs-8 mb-10">
                Sadece pdf uzantılı dosyalar (maks 2mb)
              </div>

              <div class="mb-10">
                <label class="form-label required">İş Tecrübesi</label>
                <Field
                  name="candidateExperience"
                  class="form-control form-control-lg form-control-solid"
                />
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="mb-10">
                    <label class="form-label required">Bitirdiği Bölüm</label>
                    <Field
                      name="candidateDepartment"
                      class="form-control form-control-lg form-control-solid"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-10">
                    <label class="form-label required">Bitirdiği Meslek</label>
                    <Field
                      name="candidateProfession"
                      class="form-control form-control-lg form-control-solid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!--end::Aday Ekle-->
            <div class="d-flex justify-content-center">
                <!--begin::Submit button-->
                <button
                  type="submit"
                  id="kt_account_profile_details_submit"
                  ref="submitButton"
                  class="btn btn-primary"
                >
                  <span class="indicator-label"
                    >{{ $t("common.button.invite") }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t("common.pleaseWait") }}
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span>
                  </span>
                </button>
                <!--end::Submit button-->
              </div>

          </div>
          <!--end::Card body-->
        </div>
        <!--end::Contacts-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollResponse = ref<PollResponseListModel[]>([]);
    const pollChatLogs = ref<PollMessageListModel[]>([]);

    const getPoll = async () => {
      isLoading.value = true;

      const pollResponseModel: PollResponseListModel = {
        id: 146,
      };

      pollsterController
        .pollResponseList(pollResponseModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollResponse.value, response.getValue());
          } else {
            if (pollResponse.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getPollChatLogs = async () => {
      isLoading.value = true;

      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: 146,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
            console.log(pollChatLogs.value);
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "userPolls" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getPoll();
      getPollChatLogs();
    });

    return {
      questionTypeLabel,
      pollResponse,
      pollChatLogs,
      isLoading,
    };
  },
});
</script>
